<template>
  <div class="header-social-wrapper">
    <div
      class="header-social-phone"
      :class="{'phone-green': isFixed}"
    >
      <a :href="getCleanedPhoneForHref(phone)">{{ phone }}</a>
    </div>
    <div class="header-social-links">
      <a
        :href="getCleanedPhoneForHref(phone)"
        class="btn primary"
      >ПОЗВОНИТЬ НАМ</a>
      <a
        v-for="item in socialList"
        :key="item.label"
        :href="item.label === 'telegram' ? getTelegramPhoneForHref(item.value) : getWhatsAppPhoneForHref(item.value)"
        class="btn primary btn-link"
        target="_blank"
      >
        <img
          :src="item.img"
          alt="img"
        >
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { phoneFormat, getCleanedPhoneForHref, getTelegramPhoneForHref, getWhatsAppPhoneForHref } from '~/utils/index';
import { useAsset } from '~/composables/useAssets';

const { phone, telegram, whatsapp } = defineProps<{
  telegram: string;
  whatsapp: string;
  phone: string;
  isFixed: boolean;
}>();

interface IContactList {
  img: string;
  value: string;
  isShow: boolean;
  label: string;
}

const socialList: IContactList[] = [
  {
    img: useAsset('img/svg/social/telegram.svg'),
    value: telegram,
    isShow: Boolean(telegram),
    label: 'telegram'
  },
  {
    img: useAsset('img/svg/social/whatsapp.svg'),
    value: whatsapp,
    isShow: Boolean(whatsapp),
    label: 'whatsapp'
  }
];

</script>

<style lang="scss" scoped>
.header-social-wrapper {
  display: flex;
  gap: 28px;
  align-items: center;
}

.header-social-phone a {
  color: var(--text-white);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.22px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}

.phone-green a {
  color: var(--primary);
}

.header-social-links {
  display: flex;
  align-items: center;
  gap: 6px;
}

.btn-link {
  padding: 0;
}

@media(max-width: 640px) {
  .header-social-wrapper {
    flex-direction: column-reverse;
    gap: 0;
    align-items: flex-end;
  }

  .header-social-phone {
    display: none;
  }

  .header-social-links {
    margin-bottom: 0;
  }
}
</style>
